<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">设备运行状态</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="100px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="设备编号:">
                <el-input
                  style="width: 100%"
                  v-model="FormData.device_id"
                  placeholder="请输入设备编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="1"></el-col> -->
            <el-col :span="8">
              <el-form-item label="车型:">
                <el-select
                  style="width: 100%"
                  v-model="FormData.type_id"
                  placeholder="请选择充电类型"
                  clearable
                >
                  <el-option
                    v-for="car in carList"
                    :key="car.value"
                    :value="car.value"
                    :label="car.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="规格型号:">
                <el-select
                  style="width: 100%"
                  v-model="FormData.Specification_type"
                  placeholder="请选择规格型号"
                  clearable
                >
                  <el-option
                    v-for="item in Specifs"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col v-show="isadmin" :span="8">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col  v-show="isadmin" :span="1"></el-col> -->
            <el-col :span="8">
              <el-form-item label="开通时间" class="time_pick">
                <el-date-picker
                  style="width: 100%; height: 40px"
                  v-model="FormData.actionTime"
                  type="daterange"
                  unlink-panels
                  range-separator=" - "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div
                style="margin-left: 60px"
                class="BtnSearch"
                @click="searchBtn()"
              >
                查询
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="480px"
          style="width: 96%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="设备编号" prop="device_id" width="130" />
          <el-table-column label="充电类型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <el-table-column label="设备状态" prop="state">
            <template #default="scope">
              <span></span>
              <span v-if="scope.row.state == '空闲'" style="color: limegreen"
                >空闲</span
              >
              <span v-if="scope.row.state == '离线'" style="color: red"
                >离线</span
              >
              <span v-if="scope.row.state == '故障'" style="color: red"
                >故障</span
              >
              <span v-if="scope.row.state == '充电'" style="color: orange"
                >充电中</span
              >
            </template>
          </el-table-column>
          <el-table-column label="网络状态" prop="device_state" >
            <template #default="scope">
              <span></span>
              <span v-if="scope.row.device_state == '正常'" style="color: limegreen"
                >正常</span
              >
              <span v-if="scope.row.device_state == '离线'" style="color: red"
                >离线</span
              >
            </template>
          </el-table-column>
          <el-table-column label="信号强度" prop="wx_id" />
          <el-table-column label="设备温度(℃)" prop="wx_id" width="110" />
          <el-table-column label="生产厂商" prop="manufacturer" />
          <el-table-column label="规格类型" prop="Specification_type">
            <template #default="scope">
            <span v-if="scope.row.Specification_type == 0">单路充电</span>
              <span v-if="scope.row.Specification_type == 1">双路充电</span>
            </template>
          </el-table-column>

          <el-table-column label="所属省" prop="province" />
          <el-table-column label="所属市" prop="city" />
          <el-table-column label="所属县区" prop="county" />
          <el-table-column label="详细地址" prop="community" width="150" />

          <el-table-column label="开通时间" prop="opening_time" width="160" />
          <el-table-column label="安装时间" prop="opening_time" width="160" />
          <el-table-column label="运营商" prop="username" />
        </el-table>
        
      </div>
      <div class="pagination">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
    </div>
  </div>
</template>
  <script>
// import { ref } from 'vue'
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_device, operator_data } from "@/request/api";
import ProvinceCityCountry from "../../assets/json/address";
import { regionData } from "element-china-area-data";
// import axios from "axios";
import QRCode from "qrcodejs2";
export default {
  name: "EditArea",
  data() {
    return {
      Dialog1: {
        editLevy: false,
        DialogData: {},
      },
    };
  },
  methods: {},

  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        actionTime: ["", ""],
      },
      FormData2: {},
      FormData3: {},
      radio: 1,
      isadmin: false,
      users: [],
      tableData: [
        {
          serial_number: "YH001",
          user_name: "张三",
        },
      ],
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",
      provinceData: ProvinceCityCountry.address,
      // options: regionData,
      // selectedOptions: "",
      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: "",
      city: "",
      county: "",

      loading: false,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
      Specifs: [
        {
          value: 0,
          label: "单路充电",
        },
        {
          value: 1,
          label: "双路充电",
        },
      ],
    });

    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };

    const getList = () => {
      let stDate;
      let endDate;
      if (data.FormData.actionTime) {
        stDate = data.FormData.actionTime[0] ? data.FormData.actionTime[0] : null;
        endDate = data.FormData.actionTime[1] ? data.FormData.actionTime[1] : null;
      } else if (
        data.FormData.actionTime === null ||
        data.FormData.actionTime === undefined ||
        data.FormData.actionTime === ""
      ) {
        stDate = null;
        endDate = null;
      }
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
        username: data.FormData.username,
        device_id: data.FormData.device_id,
        type_id: data.FormData.type_id,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        Specification_type: data.FormData.Specification_type,
        page: data.currentPage,
      };
      data.loading = true;
      sel_device(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    const handleChange = (value) => {};

    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };

    const initProvinceList = () => {
      // 初始化省份列表数据源
      regionData.forEach((tab) => {
        data.provinceList.push(tab);
      });
    };
    const handleProvinceChange = (val) => {
      data.cityList = [];
      data.countyList = [];
      data.province = val;
      data.FormData.city = "";
      data.FormData.county = "";
      initCityList();
    };

    const initCityList = () => {
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1) => {
        if (tab1.label == data.province) {
          data.cityList.push(tab1.children);
        }
      });
    };

    const handleCityChange = (value1) => {
      data.countyList = [];
      data.city = value1;
      data.FormData.county = "";
      initCountyList();
    };

    const initCountyList = () => {
      // 初始化县/区列表数据源
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2) => {
        // tab2.forEach((tab3)=>{
        //   console.log(tab3,'tab3');
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if (tab2.label == data.city) {
          data.countyList.push(tab2.children);
        }
      });
    };

    // const goSeekHelpDetail = () =>{
    //   router.push({
    //     name:'taskSeekHelpDetail',
    //     query:{
    //       id: '1'
    //     }
    //   })

    onMounted(() => {
      getList();
      getUsername();

      initProvinceList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      handleChange,
      handleSizeChange,
      handleCurrentChange,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
      getUsername,
    };
  },
};
</script>
  <style scoped>
.formDiv {
  margin-top: 10px;
  height: 120px;
}
.formDiv1 {
  margin-top: 10px;
  height: 220px;
}
.bottomDiv {
  height: 100%;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch1 {
  float: left;
  width: 80px;
  margin: 0 4px;
}
.el-link {
  margin: 0 3px;
}
</style>
  